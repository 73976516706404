<template>
  <plan-card-layout :tabs="tabsData" :apiLoading="apiLoading" :isSelected="isSelected">
    <template slot="title">
      {{ plan.name }}
    </template>
    <template slot="description">
      <span>
        {{plan.description}}
      </span>
    </template>
    <template slot="sub-heading">
      <v-divider></v-divider>
          <div class="hb-text-light pt-4 px-6">
            The occupancy tiers will determine the advanced rental/reservation window in days for any given space groups.
          </div>
    </template>
    <template v-for="tab in tabsData">
      <template :slot="tab.key">
        <component :is="tab.componentName" v-bind="tab.props" :key="tab.key" />
      </template>
    </template>
    <!-- <template slot="option">
      <hb-menu options>
        <v-list>
          <v-list-item :ripple="false">
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isDefault" :ripple="false">
            <v-list-item-title>Set as Default</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="!isDefault" :ripple="false">
            <v-list-item-title>Delete</v-list-item-title>
          </v-list-item>
        </v-list>
      </hb-menu>
    </template> -->
  </plan-card-layout>
</template>

<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import PlanCardLayout from "../revenue_management/utils/PlanCardLayout.vue";
import ApwPlanList from "./ApwPlanList.vue";

export default {
  name: "ApwCard",
  mixins: [notificationMixin],
  props: ['isSelected', 'plan'],
  computed: {
    tabsData() {
      return [
        {
          label: "Reservation",
          key: "reservation",
          componentName: "ApwPlanList",
          props: {setting: this.plan.reservation_settings, planType: 'Reservation'}

        },
        {
          label: "Rental",
          key: "rental",
          componentName: "ApwPlanList",
          props: {setting: this.plan.rental_settings, planType: 'Rental'}

        }
      ]
    },
  },
  components: {
    PlanCardLayout,
    ApwPlanList
  },
  data() {
    return {
      apiLoading: false,
      confirmationType: "delete",
      tab: 0,
    };
  },
  methods: {
  },
};
</script>
<style scoped></style>