<template>
    <v-row no-gutters>
        <div class="hb-inline-sentence-text">
            If unit occupancy is ≥
        </div>
        <hb-text-field condensed box small v-model="apwPlan.occupancy_percentage" v-validate="'required|integer|min_value:0|max_value:99'" data-vv-scope="general"
            data-vv-name="occupancy" data-vv-as="Occupancy" :error="errors.collect('general.occupancy').length > 0"
            placeholder="0"
        >
            <template v-slot:append>
                %
            </template>
        </hb-text-field>

        <div v-if="reservation" class="hb-inline-sentence-text">
            limit reservation window to
        </div>
        <div v-else class="hb-inline-sentence-text">
            limit rental window to
        </div>

        <hb-text-field condensed box small v-model="apwPlan.days" v-validate="'required|integer'" data-vv-scope="general"
            data-vv-name="days" data-vv-as="Days" :error="errors.collect('general.days').length > 0"
            placeholder="0">
        </hb-text-field>
        <div class="hb-inline-sentence-text">
            Days
        </div>
        <HbBtn v-if="thIndex > 0" class="mt-n1 mx-n2" icon tooltip="Clear"  mdi-code="mdi-close-circle" @click="$emit('delete-threshold', thIndex)"/>
    </v-row>
</template>
<script>
export default {
    name: 'ApwPlanThreshold',
    data() {
        return {
        };
    },
    computed: {
        apwPlan: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    props: ['value','reservation','thIndex'],
    methods: {

    }
}
</script>
<style lang="scss" scoped></style>